import React from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image"
import { graphql } from 'gatsby'
import { Row, Col } from "react-bootstrap"

export default ({ data, pageContext }) => {
    const { pieceType, pieceInfo } = pageContext
    const images = data.images.nodes
    const exhibited = pieceInfo.exhibitedAt ? (
        <p>
            {pieceInfo.exhibitedAt}
        </p>
    ) : ""
    return (
        <Layout>
            <SEO title={`Elnaz Nourizadeh ${pieceType} ${pieceInfo.title} ${pieceInfo.material}`} />
            <Row>
                <Col md={6} className="px-5 py-4">
                    <Img fluid={data.mainImage.childImageSharp.fluid} />
                </Col>
                <Col md={6} className="px-5 py-4">
                    <h2>{pieceInfo.title}</h2>
                    <p>
                        {pieceInfo.date}<br/>
                        {pieceInfo.material}<br/>
                        {pieceInfo.size}
                    </p>
                    {exhibited}
                    <p style={{"text-align": "justify"}}>
                        {pieceInfo.desc}
                    </p>
                </Col>
            </Row>
            <Row>
                {images.map(i =>
                    <Col md={6} className="px-5 py-4">
                        <Img fluid={i.childImageSharp.fluid} />
                    </Col>
                )
                }
            </Row>
        </Layout>
    )
}

export const query = graphql`
    query($directory: String!, $pieceType: String!) {
        images: allFile(sort: {fields: name}, filter: {lowerCaseBase: {ne: "main.jpg"}, pieceDirectory: {eq: $directory}, pieceType: {eq : $pieceType}}) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
            relativeDirectory
            base
            lowerCaseBase
          }
        }
        mainImage: file(lowerCaseBase: {eq: "main.jpg"}, pieceDirectory: {eq: $directory}, pieceType: {eq : $pieceType}) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }`